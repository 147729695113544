<template>
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <h2 class="brand-text text-primary ml-1">
        <h2 class="brand-text text-primary ml-1">
          <b-img
            :src="logo"
            :alt="logoAlt"
            fluid
            width="150"
            height="73"
          />
        </h2>
      </h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">{{  $t('auth.authentication_failed') }}</h2>
        <p class="mb-2">
          {{  $t('auth.not_authorized') }}
          <a :href="'mailto:' + supportEmail">{{  $t('auth.contact_support') }}</a>.
        </p>
        <b-button
          :to="{ path: '/login' }"
          class="mb-2 btn-sm-block"
          variant="primary"
          >
          {{ $t("auth.back_to_login") }}
          </b-button
        >
        <b-img :src="imgUrl" alt="Error page" fluid />
      </div>
    </div>
  </div>
</template>

<script>
import { BButton, BImg, BLink } from "bootstrap-vue";
import store from "@/store";
import { computed, ref } from "@vue/composition-api/dist/vue-composition-api";
import Tenant from "@/plugins/tenant";

export default {
  components: {
    BLink,
    BButton,
    BImg,
  },
  setup() {
    let logo = ref(
      require("@/assets/images/logo/Logo_MonicaGroups_Couleurs_RVB.svg")
    );
    let logoAlt = ref("Monica Groups");
    let isGroupX = ref(false);
    let supportEmail = ref('support@monicagroups.freshdesk.com');
    const tenantId = Tenant.resolveTenantId();

    if (tenantId === "transat" || tenantId === "groupx") {
      isGroupX.value = true;
      logo = require("@/assets/images/logo/GroupX_Logo_Clr.png");
      logoAlt.value = "GroupX";
      supportEmail.value = 'servicesreseaux.memberservices@transat.com';
    }
    let imgUrl = require("@/assets/images/pages/not-authorized.svg");

    computed(() => {
      if (store.state.appConfig.layout.skin === "dark") {
        imgUrl = require("@/assets/images/pages/not-authorized-dark.svg");
      }
    });

    return {
      imgUrl,
      logo,
      logoAlt,
      supportEmail
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
</style>
